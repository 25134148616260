import React from 'react'
import Layout from '../components/layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import PageHeading from '../UI/PageHeading/PageHeading'
import { Text, Title } from './../UI/Typing'
import Blank from '../UI/Blank/Blank'
import rectBlank from '../Assets/rectBlank.png'
import Animotion from '../UI/Animotion'
import { FormattedMessage } from 'gatsby-plugin-intl'
import './rooms.styl'

// import images
import HeadImg from './../Assets/story/storia-1.png'
import Room1s from './../Assets/story/cavoli.jpg'
import Room2m from './../Assets/story/history1.jpg'
//import Room2s from './../Assets/story/2_sub.png'

const Line = () => <span className='Line' />

const RoomColumn2 = props => (
  <div className={`RoomColumn ${props.position}`}>
    <div>
      <div>
        <div>
          <Blank sizer={rectBlank} image={props.subimage} />
        </div>
        <RoomInfo {...props} />
      </div>
    </div>
  </div>
)

const RoomInfo = props => (
  <aside>
    <Animotion>
      <Title>{props.title}</Title>
      <Line />
      <Text>{props.description}</Text>
    </Animotion>
  </aside>
)

const RoomPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <PageTransition>
      <PageHeading
        title={<FormattedMessage id='history' />}
        subtitle={<FormattedMessage id='historySubtitle' />}
        text={<FormattedMessage id='historyDescription' />}
        image={HeadImg}
      />
      <section className='Rooms'>
        <Animotion>
          <RoomColumn2
            position='right'
            description={<FormattedMessage id='history2Description' />}
            image={Room2m}
            subimage={Room2m}
          />
        </Animotion>
        <Animotion>
          <RoomColumn2
            position='left'
            description={<FormattedMessage id='history3Description' />}
            image={Room2m}
            subimage={Room1s}
          />
        </Animotion>
      </section>
    </PageTransition>
  </Layout>
)

export default RoomPage
